import i18next from 'i18next';
import {action, observable} from 'mobx';


export class Settings {

	constructor(rootStore) {
		this.rootStore = rootStore;

		if (!i18next) {
			throw new Error('Missing i18next instance');
		}

		i18next.on('languageChanged', this._onChangedLanguage);
		this._i18next = i18next;
	}

	@observable
	language = null;

	changeLanguage(language) {
		const {_i18next} = this;
		return _i18next.changeLanguage(language);
	}

	@action.bound
	_onChangedLanguage() {
		const {_i18next} = this;

		// It's important for the application that the language short code is
		// defined. It's not possible to force i18next itself to always return
		// them. So we need to always take the first element of the code.
		// For example:
		// * 'de'    ----> 'de'
		// * 'de-DE' ----> 'de'
		this.language = _i18next.language.split('-')[0].toLowerCase();

		this.rootStore.onUpdateLanguage();
	}

}
