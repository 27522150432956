import {inject, observer, PropTypes} from 'mobx-react';
import {object, shape, string} from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translation} from 'react-i18next';

import Loader from 'assets/img/loader.svg';

import Project from './Project';


@inject('calculator', 'settings')
@observer
class Projects extends Component {

	static propTypes = {
		calculator: shape({
			data: PropTypes.observableArray,
			projects: PropTypes.observableObject.isRequired,
			selectedProject: object
		}).isRequired,
		settings: shape({
			language: string
		}).isRequired
	}

	constructor(props) {
		super(props);

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'VirtualPageView',
			'page': {
				'title': 'Select project',
				'url': `${window.location.href.replace(/\/$/, '')}/projects`
			}
		});
	}

	render() {
		const
			{calculator: {state}} = this.props
		;

		let
			content
		;

		if (state === 'pending') {
			content = this.loading();
		} else if (state === 'error') {
			content = this.error();
		} else {
			content = this.content();
		}

		return (
			<div className="content content-projects">
				<div>
					{content}
				</div>
			</div>
		);
	}

	loading() {
		return (
			<div className="loading">
				<Loader />
				<span><Translation>{(t) => t('common::Load projects...')}</Translation></span>
			</div>
		);
	}

	error() {
		return (
			<div className="error">
				<span>
					<Translation>{(t) => t('common::Errors__Error while loading projects...')}</Translation>
				</span>
			</div>
		);
	}

	content() {
		const
			{calculator: {data, projects, selectedProject}, settings: {language}} = this.props,
			sum = data.reduce((sum, {result}) => sum + result, 0)
		;

		return (
			<Fragment>
				<h2>
					<Translation>{(t) => t('StepProject__Support a Climate protection-project of your choice')}</Translation>
				</h2>
				<div className="projects">
					{
						projects[language].map((project, i) =>
							(<Project
								key={project.id}
								isActive={(!selectedProject && i === 0) || selectedProject && project.id === selectedProject.id}
								isRecommend={i === 0}
								project={project}
								sum={sum} />))
					}
				</div>
			</Fragment>
		);
	}

}

export default Projects;
