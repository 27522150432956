import BaseChoiceField from './BaseChoice';


const
	FIELD_TYPE = 'radio'
;


class Radio extends BaseChoiceField {

	get fieldType() {
		return FIELD_TYPE;
	}

}

export default Radio;
