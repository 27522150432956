// import {Carousel} from 'caroucssel';
import {arrayOf, elementType, func, shape, string} from 'prop-types';
import React, {Component, createRef} from 'react';
import {Translation} from 'react-i18next';


class Navigation extends Component {

	static propTypes = {
		items: arrayOf(
			shape({
				icon: elementType,
				name: string
			})
		).isRequired,
		onChange: func.isRequired,
		active: string
	}

	static defaultProps = {
		active: ''
	}

	constructor(props) {
		super(props);

		this._onClick = this._onClick.bind(this);
	}

	_ref = createRef();

	// componentDidMount() {
	// 	const
	// 		{_ref: {current}} = this,
	// 		carousel = new Carousel(current)
	// 	;
	//
	// 	this._carousel = carousel;
	// }

	render() {
		const
			{_ref, props: {active, items}} = this
		;

		return (
			<div className="navigation">
				<div>
					<div ref={_ref} className="nav-items">
						{
							items.map(({icon: Icon, name}) => {
								var
									label
								;

								switch (name) {
									case 'plane':
										label = (<Translation>{(t) => t('common::Plane')}</Translation>);
										break;
									case 'car':
										label = (<Translation>{(t) => t('common::Car')}</Translation>);
										break;
									default:
										label = (<Translation>{(t) => t('common::General')}</Translation>);
								}

								return (
									<div key={name} className={['item', `item-${name}`, active === name ? 'is-active' : null].filter((className) => !!className).join(' ')}>
										<button data-name={name} type="button" onClick={this._onClick}>
											<Icon viewBox="0 0 100 100" />
											{label}
										</button>
									</div>
								);
							})
						}
					</div>
				</div>
			</div>
		);
	}

	_onClick(event) {
		const
			{onChange} = this.props,
			name = event.currentTarget.getAttribute('data-name')
		;

		onChange(name);
	}

}

export default Navigation;
