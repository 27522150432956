import {inject} from 'mobx-react';
import {arrayOf, func, shape, string} from 'prop-types';
import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Picture} from 'react-responsive-picture';

import CertificateImage from 'assets/img/certificate.svg';
import Explosion from 'assets/img/explosion.svg';
import IconBusiness from 'assets/icons/icon-business.svg';
import IconLink from 'assets/icons/icon-link.svg';
import IconNewsletter from 'assets/icons/icon-newsletter.svg';

import Navigation from '../navigation/Navigation';


@inject('calculator')
class Certificate extends Component {

	static propTypes = {
		calculator: shape({
			certificate: string
		}).isRequired,
		navigationItems: arrayOf(
			shape({
				name: string,
				label: string,
				loader: func
			})
		).isRequired,
		onChangeNavigation: func.isRequired
	}

	constructor(props) {
		super(props);

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'VirtualPageView',
			'page': {
				'title': 'Certificate',
				'url': `${window.location.href.replace(/\/$/, '')}/certificate`
			}
		});
	}

	render() {
		const
			{calculator, calculator: {certificate, selectedProject}, navigationItems, onChangeNavigation} = this.props,
			date = new Date(),
			currentDate = date.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}),
			sources = selectedProject.images[0]?.thumbnails.map(({url, url2x, width}) => {
				return {
					srcSet: `${url} 1x, ${url2x} 2x`,
					media: `(max-width: ${width})`,
				};
			}),
			defaultSrc = selectedProject.images[0]?.thumbnails[0].url
		;

		calculator.data = [];
		calculator.selectedProject = null;

		return (
			<div className="content content-certificate">
				<div>
					<Translation>{(t) =>
						(<div className="thanks">
							<h2 dangerouslySetInnerHTML={{__html: t('StepCertificate__Thanks headline')}} />
							<p dangerouslySetInnerHTML={{__html: t('StepCertificate__Thanks text', {currentDate})}} />
						</div>)
					}</Translation>

					<div className="certificate">
						<CertificateImage />
						<div className={['image', !defaultSrc && 'no-image'].filter((name) => !!name).join(' ')}>
							<Explosion />
							{defaultSrc && (<Picture sources={sources} src={defaultSrc} />)}
						</div>
					</div>

					<div className="text">
						<a href={certificate} rel="noopener noreferrer" target="_blank">
							<Translation>{(t) => t('StepCertificate__Download certificate')}</Translation>
						</a>
						<p><Translation>{(t) => t('StepCertificate__Certificate text')}</Translation></p>
						<p><strong><Translation>{(t) => t('StepCertificate__Certificate thanks')}</Translation></strong></p>
					</div>

					<div className="calculate-more">
						<h3><Translation>{(t) => t('StepCertificate__Certificate more')}</Translation></h3>
						<Navigation key="navigation" items={navigationItems} onChange={onChangeNavigation} />
					</div>

					<div className="meta-navigation">
					<div>
							<h4>
								<span>
									<IconBusiness viewBox="0 0 100 100" />
								</span>
								<Translation>{(t) => t('StepCertificate__Certificate meta business calculator headline')}</Translation>
							</h4>
							<p>
								<Translation>{(t) => t('StepCertificate__Certificate meta business calculator text')}</Translation>
							</p>
							<a href="https://www.climatepartner.com/de/leistungen/ccf" rel="noopener noreferrer" target="_blank">
								<IconLink viewBox="0 0 100 100" />
								<Translation>{(t) => t('StepCertificate__Certificate meta business calculator link title')}</Translation>
							</a>
						</div>
						<div>
							<h4>
								<span><IconNewsletter viewBox="0 0 100 100" /></span>
								<Translation>{(t) => t('StepCertificate__Certificate meta newsletter headline')}</Translation>
							</h4>
							<p><Translation>{(t) => t('StepCertificate__Certificate meta newsletter text')}</Translation></p>
							<a href="https://eepurl.com/dxtlqT" rel="noopener noreferrer" target="_blank">
								<IconLink viewBox="0 0 100 100" />
								<Translation>{(t) => t('StepCertificate__Certificate meta newsletter link text')}</Translation>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default Certificate;
