import {arrayOf, number} from 'prop-types';
import React, {Fragment} from 'react';

import IconArrow from 'assets/icons/icon-arrow.svg';

import BaseChoiceField from './BaseChoice';


class Select extends BaseChoiceField {

	static propTypes = {
		...super.propTypes,
		seperatorAt: arrayOf(number)
	}

	static defaultProps = {
		...super.defaultProps,
		seperatorAt: []
	}

	constructor(props) {
		super(props);

		this.state = {
			value: props.value || ''
		};

		this.onChange = this.onChange.bind(this);
	}

	render() {
		const
			{classNames: classes, component: Component, disabledOptions, label, name, name: id, options, seperatorAt, errors} = this.props,
			{value} = this.state,
			error = [].concat(errors).length > 0 ?
				[].concat(errors)[0].messages
					.map((message, index) => <li key={index}>{message}</li>) :
				null,
			classNames = [
				...classes,
				'generic-form-field',
				'generic-select',
				'select-' + name.toLowerCase(),
				error ? 'has-errors' : null
			].filter((className) => !!className).join(' '),
			onChange = this.onChange
		;

		return (<Component className={classNames}>
			<label htmlFor={id}>
				{label && <em>{label}</em>}
				<div className="input-wrapper">
					<IconArrow viewBox="0 0 100 100" />
					<select {...{id, name, value, onChange}}>
						{
							options.map(({id, id: value, name}, i) => {
								const
									disabled = disabledOptions.includes(id),
									seperator = seperatorAt.includes(i) && (<option disabled>──────────</option>),
									option = (<option {...{disabled, value}}>{name}</option>)
								;

								return (<Fragment key={i}>
									{seperator}
									{option}
								</Fragment>);
							})
						}
					</select>
				</div>
			</label>
			{error && <ul className="error-label">{error}</ul>}
		</Component>);
	}

	onChange(event) {
		const
			{name, onChange} = this.props,
			{value} = event.currentTarget
		;

		this.setState(() => ({value}));

		onChange(name, value);
	}
}

export default Select;
