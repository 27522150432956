import React, {Component, createRef, Fragment} from 'react';
import {any, arrayOf, func, number, oneOfType, shape, string} from 'prop-types';

import IconX from 'assets/icons/icon-x.svg';
import IconArrow from 'assets/icons/icon-arrow.svg';


class InputText extends Component {
	static propTypes = {
		name: string.isRequired,
		classNames: arrayOf(string),
		component: any,
		errors: oneOfType([
			arrayOf(
				shape({
					field: string,
					messages: arrayOf(
						string
					)
				})
			),
			shape({
				field: string,
				messages: arrayOf(
					string
				)
			})
		]),
		helpText: string,
		label: string,
		maxLength: number,
		maxValue: number,
		minValue: number,
		step: number,
		type: string,
		value: oneOfType([number, string]),
		onChange: func
	}

	static defaultProps = {
		classNames: [],
		component: 'div',
		errors: [],
		helpText: null,
		label: null,
		maxLength: null,
		maxValue: null,
		minValue: 0,
		step: null,
		type: 'text',
		value: '',
		onChange: () => {/* NOOP */}
	}

	constructor(props) {
		super(props);

		this._defaultValue = props.value;

		this.state = {
			value: props.value
		};

		this.onChange = this.onChange.bind(this);
		this._onIncrement = this._onIncrement.bind(this);
		this._onDecrement = this._onDecrement.bind(this);
		this._onReset = this._onReset.bind(this);
	}

	_refInput = createRef()

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value
			});
		}
	}

	render() {
		const
			{classNames: classes, component: Component, label, name, helpText, type, name: id, errors, minValue, maxLength, maxValue, step} = this.props,
			{value} = this.state,
			error = [].concat(errors).length > 0 ?
				[].concat(errors)[0].messages
					.map((message, index) => <li key={index}>{message}</li>) :
				null,
			classNames = [
				...classes,
				'generic-form-field',
				'generic-input-text',
				'input-text-' + name.toLowerCase(),
				error ? 'has-errors' : null
			].filter((className) => !!className).join(' '),
			onChange = this.onChange
		;

		var
			button
		;

		if (type === 'text' && value.length > 0) {
			button = (<button className="clear" tabIndex="-1" type="button" onClick={this._onReset}><IconX viewBox="0 0 100 100" /></button>);
		} else if (type === 'number') {
			button = (
				<Fragment>
					<button className="up" tabIndex="-1" type="button" onClick={this._onIncrement}><IconArrow viewBox="0 0 100 100" /></button>
					<button className="down" tabIndex="-1" type="button" onClick={this._onDecrement}><IconArrow viewBox="0 0 100 100" /></button>
				</Fragment>
			);
		}

		return (<Component className={classNames}>
			<label htmlFor={id}>
				{label && <em>{label}</em>}
				<div className="input-wrapper">
					<input ref={this._refInput} {...{id, name, value, onChange, min: minValue, max: maxValue, maxLength, step, type}} />
					{button}
				</div>
			</label>
			{error && <ul className="error-label">{error}</ul>}
			{helpText && <sub className="helptext" dangerouslySetInnerHTML={{__html: helpText}} />}
		</Component>);
	}

	onChange(event) {
		const
			{name, onChange} = this.props,
			{type, value} = event.currentTarget
		;

		var
			newValue = value
		;

		if (type === 'number') {
			newValue = parseFloat(value === '' ? 0 : value);
		}

		this.setState(() => ({value: newValue}));

		onChange(name, newValue);
	}

	_onIncrement() {
		const
			{maxValue, name, onChange, step} = this.props
		;

		this.setState(({value}) => {
			const
				newValue = Math.round(100 * (value + (step ?? 1))) / 100
			;

			return {value: (maxValue ? Math.min(maxValue, newValue) : newValue)};
		}, () => {
			onChange(name, this.state.value);
		});
	}

	_onDecrement() {
		const
			{minValue, name, onChange, step} = this.props
		;

		this.setState(({value}) => {
			const
				newValue = Math.round(100 * (value - (step ?? 1))) / 100
			;

			return {value: Math.max(minValue, newValue)};
		}, () => {
			onChange(name, this.state.value);
		});
	}

	_onReset() {
		this.setState(() => {
			return {value: this._defaultValue};
		});
	}
}

export default InputText;
