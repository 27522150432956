import {inject, observer, PropTypes} from 'mobx-react';
import {func, number, shape} from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translation} from 'react-i18next';

import IconCar from 'assets/icons/icon-car.svg';
import IconGeneral from 'assets/icons/icon-general.svg';
import IconPlane from 'assets/icons/icon-plane.svg';
import IconPlus from 'assets/icons/icon-plus.svg';

import Item from './Item';


@inject('calculator')
@observer
class Result extends Component {

	static propTypes = {
		calculator: shape({
			data: PropTypes.observableArray,
			fetchResult: func,
			step: number
		}).isRequired
	}

	constructor(props) {
		super(props);

		this._onAddMore = this._onAddMore.bind(this);
	}

	render() {
		const
			{calculator: {data}} = this.props,
			sum = data.reduce((sum, {result}) => sum + result, 0),
			orderedData = data.reduce((prev, cur) => {
				const
					keys = Object.keys(prev)
				;

				if (keys.includes(cur.namespace)) {
					return {...prev, [cur.namespace]: [...prev[cur.namespace], cur]};
				}

				return {...prev, [cur.namespace]: [cur]};
			}, {}) || {},
			keys = Object.keys(orderedData)
		;

		return (
			<div className="content content-result">
				<div>
					<Translation>
						{(t) => (<h2 dangerouslySetInnerHTML={{__html: t('StepProject__result__Your calculated CO<sub>2</sub>-Amount')}} />)}
					</Translation>
					<div className="calculation">
						{
							keys.map((key) => {
								const
									data = orderedData[key] || [],
									calc = data.map((data, i) => {
										return (<Item key={i} data={data} />);
									})
								;

								var
									Icon,
									label
								;

								switch (key) {
									case 'plane':
										Icon = IconPlane;
										label = (<Translation>{(t) => t('common::Plane')}</Translation>);
										break;
									case 'car':
										Icon = IconCar;
										label = (<Translation>{(t) => t('common::Car')}</Translation>);
										break;
									default:
										Icon = IconGeneral;
										label = (<Translation>{(t) => t('common::General')}</Translation>);
								}

								return (
									<Fragment key={key}>
										<h4>
											<span><Icon viewBox="0 0 100 100" /></span>
											{label}
										</h4>
										<ul>
											{calc}
										</ul>
									</Fragment>
								);
							})
						}
						<div className="summary">
							<span><Translation>{(t) => t('StepProject__result__Total')}</Translation></span>
							<span>{new Intl.NumberFormat('de-DE', {maximumSignificantDigits: sum.toString().length}).format(sum)} kg CO<sub>2</sub></span>
						</div>
						<div className="add-more">
							<button type="button" onClick={this._onAddMore}>
								<IconPlus viewBox="0 0 100 100" />
								<Translation>{(t) => t('StepProject__result__Calculate more emissions')}</Translation>
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	_onAddMore() {
		const
			{calculator} = this.props
		;

		calculator.step = 1;
	}

}

export default Result;
