import {inject, PropTypes} from 'mobx-react';
import {func, number, shape} from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translation} from 'react-i18next';
import {Picture} from 'react-responsive-picture';

import IconArrow from 'assets/icons/icon-arrow.svg';
import IconCar from 'assets/icons/icon-car.svg';
import IconGeneral from 'assets/icons/icon-general.svg';
import IconPlane from 'assets/icons/icon-plane.svg';
import {TAX_VALUE} from 'settings/constants';

import Item from './Item';


@inject('calculator')
class Result extends Component {

	static propTypes = {
		calculator: shape({
			data: PropTypes.observableArray,
			selectedProject: PropTypes.observableObject,
			fetchResult: func,
			step: number
		}).isRequired
	}

	constructor(props) {
		super(props);

		this._onRestart = this._onRestart.bind(this);
	}

	render() {
		const
			{calculator: {data, selectedProject}} = this.props,
			sum = data.reduce((sum, {result}) => sum + result, 0),
			orderedData = data.reduce((prev, cur) => {
				const
					keys = Object.keys(prev)
				;

				if (keys.includes(cur.namespace)) {
					return {...prev, [cur.namespace]: [...prev[cur.namespace], cur]};
				}

				return {...prev, [cur.namespace]: [cur]};
			}, {}) || {},
			keys = Object.keys(orderedData),
			sources = selectedProject.images[0]?.thumbnails.map(({url, url2x, width}) => {
				return {
					srcSet: `${url} 1x, ${url2x} 2x`,
					media: `(max-width: ${width})`,
				};
			}),
			defaultSrc = selectedProject.images[0]?.thumbnails[0].url
		;

		return (
			<div className="content content-basket">
				<div>
					{
						data.length < 5 &&
						(<div className={['image', !defaultSrc && 'no-image'].filter((name) => !!name).join(' ')}>
							<Picture sources={sources} src={defaultSrc} />
							<div>
								<span className="technology">{selectedProject.technology}</span>
								<span className="name">{selectedProject.name}</span>
							</div>
						</div>)
					}
					<div className="calculation">
						<div className="amount">
							{new Intl.NumberFormat('de-DE', {style: 'currency', currency: selectedProject.currency, currencyDisplay: 'code'}).format(selectedProject.price_with_tax * sum)}
							<em><Translation>{(t) => t('StepPayment__basket__Basket incl. tax', {value: TAX_VALUE})}</Translation></em>
						</div>
						{
							keys.map((key) => {
								const
									data = orderedData[key] || [],
									calc = data.map((data, i) => {
										return (<Item key={i} data={data} />);
									})
								;

								var
									headline,
									Icon
								;

								switch (key) {
									case 'car':
										headline = (<Translation>{(t) => t('common::Car')}</Translation>);
										Icon = IconCar;
										break;
									case 'general':
										headline = (<Translation>{(t) => t('common::General')}</Translation>);
										Icon = IconGeneral;
										break;
									default:
										headline = (<Translation>{(t) => t('common::Plane')}</Translation>);
										Icon = IconPlane;
								}

								return (
									<Fragment key={key}>
										<h4>
											<span><Icon viewBox="0 0 100 100" /></span>
											{headline}
										</h4>
										<ul>
											{calc}
										</ul>
									</Fragment>
								);
							})
						}
						<div className="summary">
							<span><Translation>{(t) => t('StepPayment__basket__Total')}</Translation></span>
							<span>{new Intl.NumberFormat('de-DE', {maximumSignificantDigits: sum.toString().length}).format(sum)} kg CO<sub>2</sub></span>
						</div>
					</div>
				</div>

				<button className="back" type="button" onClick={this._onRestart}>
					<IconArrow viewBox="0 0 100 100" />
					<Translation>{(t) => t('StepPayment__basket__Back to projects')}</Translation>
				</button>
			</div>
		);
	}

	_onRestart() {
		const
			{calculator} = this.props
		;

		calculator.step = 2;
	}

}

export default Result;
