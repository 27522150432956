import i18next from 'i18next';
import {inject, PropTypes} from 'mobx-react';
import React, {Component, createRef} from 'react';
import {Translation} from 'react-i18next';
import ReactDOM from 'react-dom';
import {func, object, shape, string} from 'prop-types';

import {InputText, Radio, Select} from 'components/generic/form-fields';
import SealGoals from 'assets/img/seal-goals.svg';
import SealTuevAustria from 'assets/img/seal-tuev-austria.svg';
import {TAX_VALUE} from 'settings/constants';


@inject('calculator', 'countries', 'settings')
class Payment extends Component {

	static propTypes = {
		calculator: shape({
			data: PropTypes.observableArray,
			payment: func,
			onAuthorize: func,
			selectedProject: object
		}).isRequired,
		countries: shape({
			data: PropTypes.observableObject
		}).isRequired,
		settings: shape({
			language: string
		}).isRequired
	}

	constructor(props) {
		super(props);

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'VirtualPageView',
			'page': {
				'title': 'Payment',
				'url': `${window.location.href.replace(/\/$/, '')}/payment`
			}
		});

		const
			{calculator, countries: {data: countriesData}, settings: {language}} = props,
			titles = [
				{id: 'MR', name: () => i18next.t('StepPayment__form__Mr')},
				{id: 'MS', name: () => i18next.t('StepPayment__form__Ms')},
				{id: 'MX', name: () => i18next.t('StepPayment__form__Mx')}
			],
			formFields = [
				{type: Radio, classNames: ['title-group'], label: () => i18next.t('StepPayment__form__Salutation'), name: 'contact_salutation', options: titles.map(({id, name}) => ({id, name: name()})), isRequired: true},
				{type: InputText, label: () => i18next.t('StepPayment__form__First name'), name: 'contact_first_name', isRequired: true},
				{type: InputText, label: () => i18next.t('StepPayment__form__Last name'), name: 'contact_last_name', isRequired: true},
				{type: InputText, label: () => i18next.t('StepPayment__form__Street'), name: 'contact_street', isRequired: true},
				{type: InputText, label: () => i18next.t('StepPayment__form__Nr.'), name: 'contact_street_no', isRequired: true, maxLength: 5},
				{type: InputText, label: () => i18next.t('StepPayment__form__Zip'), name: 'contact_postcode', isRequired: true, maxLength: 5},
				{type: InputText, label: () => i18next.t('StepPayment__form__City'), name: 'contact_city', isRequired: true},
				{type: Select, label: () => i18next.t('StepPayment__form__Country'), name: 'contact_country', options: countriesData[language], isRequired: true, seperatorAt: [3]},
				{type: InputText, label: () => i18next.t('StepPayment__form__E-Mail'), name: 'contact_email', isRequired: true}
			]
		;

		this.state = {
			env: calculator._paypalEnv,
			errors: [],
			formFields,
			values: {
				contact_country: countriesData[language][0].id,
				contact_salutation: titles[0].id
			}
		};

		this._validate = this._validate.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onFieldChange = this._onFieldChange.bind(this);
		this._payment = this._payment.bind(this);
		this._onAuthorize = this._onAuthorize.bind(this);
		this._onSuccess = this._onSuccess.bind(this);

		import(/* webpackChunkName: "paypay-checkout" */ 'paypal-checkout')
			.then((module) => this.setState(() => ({paypal: module.default})));
	}

	_formRef = createRef();

	get isValid() {
		const
			{formFields, values} = this.state
		;

		return formFields
			.filter(({isRequired}) => isRequired)
			.every((field) => values[field.name] && values[field.name] !== '');
	}

	render() {
		const
			{calculator: {data, selectedProject}} = this.props,
			{client, env, paypal, errors: fieldErrors, formFields, values} = this.state,
			{_formRef} = this,
			sum = data.reduce((sum, {result}) => sum + result, 0),
			onCancel = this._onCancel,
			onError = this._onError,
			payment = this._payment,
			validate = this._validate,
			onAuthorize = this._onAuthorize,
			onClick = this._onClick,
			style = {
				label: 'pay',
				layout: 'vertical',
				shape: 'rect',
				size: 'responsive'
			}
		;

		var
			PayPalButton
		;

		if (paypal) {
			PayPalButton = paypal.Button.driver('react', {React, ReactDOM});
		}

		return (
			<div className="content content-payment">
				<div>
					<h2>
						<Translation>{(t) => t('StepPayment__content__Please give us your paymentinformations.')}</Translation>
					</h2>

					<p>
						<Translation>{(t) => t('StepPayment__content__To make out a bill and a certificate, please give us your contactinformations')}</Translation>
					</p>

					<div className="form">
						<form ref={_formRef}>
							{
								formFields.map(({type: Type, classNames, label, maxLength, name, seperatorAt, options}, i) => {
									const
										errors = fieldErrors.find(({field: fieldName}) => fieldName === name),
										onChange = this._onFieldChange,
										value = values[name]
									;

									return (<Type key={i} {...{classNames, errors, label: label(), maxLength, name, options, value, seperatorAt, onChange}} />);
								})
							}
						</form>
					</div>

					<div className="payment-info">
						<h3>
							<Translation>{(t) => t('StepPayment__content__Payment')}</Translation>
						</h3>
						<div className="amount">
							{new Intl.NumberFormat('de-DE', {style: 'currency', currency: selectedProject.currency, currencyDisplay: 'code'}).format(selectedProject.price_with_tax * sum)}
							<em><Translation>{(t) => t('StepPayment__content__Payment incl tax', {value: TAX_VALUE})}</Translation></em>
						</div>
					</div>

					{paypal && (
						<div className="paypal-btn">
							<PayPalButton {...{env, client, onAuthorize, onCancel, onError, payment, style, onClick, validate}} />
						</div>
					)}

					<div className="agb">
						<h4>
							<Translation>{(t) => t('StepPayment__content__Terms')}</Translation>
						</h4>
						<Translation>
							{(t) => (<p dangerouslySetInnerHTML={{__html: t('StepPayment__content__Terms text')}} />)}
						</Translation>
						<div className="seals">
							<SealGoals />
							<SealTuevAustria />
						</div>
					</div>
				</div>
			</div>
		);
	}

	_payment() {
		const
			{calculator, calculator: {data, selectedProject}} = this.props,
			{values} = this.state,
			sum = data.reduce((sum, {result}) => sum + result, 0),
			formData = {
				...values,
				certificate_text: this._getCertificateText(data),
				emissions: sum,
				project_id: selectedProject.id
			}
		;

		return calculator.onPayment(formData);
	}

	_getCertificateText(data) {
		// Return fallback message on multiple emissions
		if (data.length > 1) {
			return i18next.t('StepPayment__certicficate-text__Certificate individual CO2 compensation');
		}

		const
			{namespace, params} = data[0]
		;

		switch (namespace) {
			case 'car':
				return i18next.t('StepPayment__certicficate-text__Climate neutral car ride', params);
			case 'plane':
				var
					{from_airport, to_airport, travel_route} = params,
					options = {
						from_airport: from_airport.toUpperCase(),
						to_airport: to_airport.toUpperCase()
					}
				;

				if (travel_route === 'return') {
					return i18next.t('StepPayment__certicficate-text__Climate neutral flights', options);
				} else {
					return i18next.t('StepPayment__certicficate-text__Climate neutral flight', options);
				}
			default:
				return i18next.t('StepPayment__certicficate-text__Certificate individual CO2 compensation');
		}
	}

	_validate(actions) {
		const
			{_formRef: {current}} = this
		;

		actions.disable();

		current.addEventListener('change', () => this._onChange(actions));
	}

	_onChange(actions) {
		this.isValid ? actions.enable() : actions.disable();
	}

	_onClick() {
		const
			{formFields, values} = this.state
		;

		if (!this.isValid) {
			formFields
				.filter(({isRequired}) => isRequired)
				.filter((field) => !(values[field.name] && values[field.name] !== ''))
				.forEach(({name}) => {
					this.setState(({errors}) => {
						return {
							errors: [
								...errors,
								{
									field: name,
									messages: [i18next.t('common::Errors__Field is required')]
								}
							]
						};
					});
				});
		}
	}

	_onCancel(reason) {
		console.log(reason);
	}

	_onError(/*error*/) {
		// @TODO: handle errors
	}

	_onSuccess(data) {
		const
			{calculator} = this.props,
			offset = window.scrollY + document.querySelector('.calculator').getBoundingClientRect().top,
			dataLayer = {
				'event': 'purchase',
				'transactionId': data.payment_reference,
				'transactionAffiliation': data.payment_type,
				'transactionTotal': data.total_price,
				'transactionProducts': [{
					'sku': calculator.selectedProject.id,
					'name': calculator.selectedProject.name,
					'category': [...new Set(calculator.data.map(({namespace}) => namespace))].join(', '),
					'price': data.total_price,
					'quantity': calculator.data.reduce((sum, {result}) => sum + result, 0)
				}]
			}
		;

		if ('scrollBehavior' in document.documentElement.style) {
			window.scrollTo({
				top: offset,
				behavior: 'smooth'
			});
		} else {
			window.scrollTo(0, offset);
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(dataLayer);

		calculator.certificate = data.certificate_url;
		calculator.step = 4;
	}

	_onAuthorize(data) {
		const
			{calculator} = this.props
		;

		return calculator.authorized(data)
			.then((data) => this._onSuccess(data));
	}

	_onFieldChange(name, value) {
		if (value !== '') {
			this.setState(({errors, values}) => {
				return {
					errors: errors.filter(({field}) => field !== name),
					values: {...values, ...{[name]: value}}
				};
			});
		} else if (value === '') {
			this.setState(({errors, values}) => {
				values[name] = undefined;
				delete(values[name]);

				return {
					errors: [
						...errors,
						{
							field: name,
							messages: [i18next.t('common::Errors__Field is required')]
						}
					],
					values
				};
			});
		}
	}

}

export default Payment;
