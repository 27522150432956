import {inject, observer, PropTypes} from 'mobx-react';
import {element, func, number, object, shape, string} from 'prop-types';
import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import Loadable from 'react-loadable';

import IconCar from 'assets/icons/icon-car.svg';
import IconGeneral from 'assets/icons/icon-general.svg';
import IconPlane from 'assets/icons/icon-plane.svg';
import IconLink from 'assets/icons/icon-link.svg';
import IconLanguage from 'assets/icons/icon-language.svg';

import Navigation from './navigation/Navigation';
import Payment from './payment/Payment';
import Progress from './progress/Progress';
import Projects from './projects/Projects';
import Result from './result/Result';
import Basket from './basket/Basket';
import Certificate from './certificate/Certificate';
import LanguageSwitch from './language/Switch';

import '../../../../client/scss/styles';


const
	calculatorNavigationItems = [
		{name: 'plane', icon: IconPlane, loader: () => import(/* webpackChunkName: "module-plane" */ 'components/calculator/modules/Plane')},
		{name: 'car', icon: IconCar, loader: () => import(/* webpackChunkName: "module-car" */ 'components/calculator/modules/Car')},
		{name: 'general', icon: IconGeneral, loader: () => import(/* webpackChunkName: "module-general" */ 'components/calculator/modules/General')}
		// {name: 'train', label: 'Zugfahrt'},
		// {name: 'ship', label: 'Kreuzfahrt'},
		// {name: 'subscription', label: 'Abos'}
	]
;

@inject('calculator')
@inject('modal')
@inject('settings')
@observer
class Calculator extends Component {

	static propTypes = {
		calculator: shape({
			data: PropTypes.observableArray,
			selectedProject: PropTypes.observableObject,
			fetchResult: func,
			step: number
		}).isRequired,
		modal: shape({
			content: element
		}).isRequired,
		settings: shape({
			language: string,
			changeLanguage: func.isRequired
		}).isRequired,
		config: object
	}

	static defaultProps = {
		config: {}
	}

	constructor(props) {
		super(props);

		this.state = {
			module: (props.config?.modules || ['plane'])[0]
		};

		this._onChange = this._onChange.bind(this);
		this._onOpenLanguageSwitch = this._onOpenLanguageSwitch.bind(this);

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'VirtualPageView',
			'page': {
				'title': 'Startpage',
				'url': `${window.location.href.replace(/\/$/, '')}/index`
			}
		});
	}

	render() {
		const
			{calculator: {step}, config} = this.props,
			{module} = this.state,
			filteredNavigationItems = calculatorNavigationItems.filter((item) => (!config.modules || config.modules.includes(item.name))),
			{loader} = filteredNavigationItems.find(({name}) => name === module),
			Component = Loadable({
				loader,
				loading() {
					return <Translation>{(t) => t('common::Loading')}</Translation>;
				}
			}),
			content = [(<Progress key="progress" />)]
		;

		switch (step) {
			case 4:
				content.push(<Certificate key="certificate" navigationItems={filteredNavigationItems} onChangeNavigation={this._onChange} />);
				break;
			case 3:
				content.unshift(<Basket key="basket" />);
				content.push(<Payment key="payment" />);
				break;
			case 2:
				content.unshift(<Result key="result" />);
				content.push(<Projects key="projects" />);
				break;
			default:
				content.unshift(<Navigation key="navigation" active={module} items={filteredNavigationItems} onChange={this._onChange} />);
				content.push(<Component key="component" step={step} />);
				content.push(
					<div key="information" className="content content-information">
						<Translation>{(t) => (
							<a href={t('StepCalculation__common__calculationmethod__link__url')} rel="noopener noreferrer" target="_blank">
								<IconLink viewBox="0 0 100 100" />
								{t('StepCalculation__common__calculationmethod__link__text')}
							</a>
						)}</Translation>
						{
							(config?.languages || []).length > 1 &&
							(<button type="button" onClick={this._onOpenLanguageSwitch}>
								<IconLanguage viewBox="0 0 100 100" />
								<Translation>{(t) => t('StepCalculation__common__Change language')}</Translation>
							</button>)
						}
					</div>
				);
		}

		return (
			<div className="calculator" id="Emissionen-berechnen">
				<div>
					{content}
				</div>
			</div>
		);
	}

	_onOpenLanguageSwitch() {
		const
			{config, modal, settings} = this.props
		;

		modal.classNames = ['modal-language-switch'];
		modal.content = (<LanguageSwitch config={config} settings={settings} />);
	}

	_onChange(name) {
		const
			{calculator} = this.props,
			offset = window.scrollY + document.querySelector('.calculator').getBoundingClientRect().top
		;

		this.setState(() => ({module: name}));

		if ('scrollBehavior' in document.documentElement.style) {
			window.scrollTo({
				top: offset,
				behavior: 'smooth'
			});
		} else {
			window.scrollTo(0, offset);
		}

		if (calculator.step === 4) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'VirtualPageView',
				'page': {
					'title': 'Startpage',
					'url': `${window.location.href.replace(/\/$/, '')}/index`
				}
			});
		}

		calculator.step = 1;
	}

}

export default Calculator;
