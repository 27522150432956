import {object} from 'prop-types';
import React, {Component} from 'react';
import {Translation} from 'react-i18next';

import {CAR_TYPES, TRAVEL_ROUTES} from 'settings/constants';


class Item extends Component {

	static propTypes = {
		data: object.isRequired
	}

	render() {
		const
			{data: {namespace, params: {car_type, from_airport, to_airport, kilometers, passengers, travel_route}, result}} = this.props,
			routeName = TRAVEL_ROUTES.find(({id}) => id === travel_route)?.name()
		;

		var
			desc
		;

		switch (namespace) {
			case 'car':
				desc = `${CAR_TYPES.find(({id}) => id === car_type)?.name()}, ${new Intl.NumberFormat('de-DE', {maximumSignificantDigits: kilometers.toString().length}).format(kilometers)}km`;
				break;
			case 'general':
				desc = (<Translation>{(t) => t('StepPayment__basket__item__individual CO2 compensation')}</Translation>);
				break;
			default:
				desc = (<Translation>{(t) => t('StepPayment__basket__item__{{from_airport}} > {{to_airport}}, {{passengers}}, {{routeName}}', {from_airport, to_airport, passengers, routeName})}</Translation>);
		}

		return (
			<li>
				<p>
					<span>{desc}</span>
					<span>
						{new Intl.NumberFormat('de-DE', {maximumSignificantDigits: result.toString().length}).format(result)} kg CO<sub>2</sub>
					</span>
				</p>
			</li>
		);
	}

}

export default Item;
