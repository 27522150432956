import {observable} from 'mobx';


export class Modal {

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	classNames = [];

	@observable
	content = null;

}
