import {observer} from 'mobx-react';
import {array, func, shape, string} from 'prop-types';
import React, {Component} from 'react';
import {Translation} from 'react-i18next';

import IconLanguage from 'assets/icons/icon-language.svg';


@observer
class Switch extends Component {

	static propTypes = {
		settings: shape({
			changeLanguage: func.isRequired,
			language: string
		}).isRequired,
		config: shape({
			languages: array
		})
	}

	static defaultProps = {
		config: {}
	}

	constructor(props) {
		super(props);

		this._onChangeLanguage = this._onChangeLanguage.bind(this);
	}

	render() {
		const
			{config: {languages}, settings: {language}} = this.props
		;

		return (
			<div className="content-language-switch">
				<div>
					<h2>
						<span><IconLanguage viewBox="0 0 100 100" /></span>
						<Translation>{(t) => t('common::Language')}</Translation>
					</h2>
					<div className="switch">
						{languages.map((lng) => (<button key={lng} className={language === lng ? 'active' : null} type="button" value={lng} onClick={this._onChangeLanguage}>{lng.toUpperCase()}</button>))}
					</div>
				</div>
			</div>
		);
	}

	_onChangeLanguage(event) {
		const
			{settings} = this.props
		;

		settings.changeLanguage(event.currentTarget.value);
	}

}

export default Switch;
