import i18next from 'i18next';


function getTaxValue() {
	const
		start = (new Date('2020-07-01T00:00:00')).getTime(),
		end = (new Date('2020-12-31T23:59:59')).getTime(),
		current = (new Date()).getTime()
	;

	return current > start && current < end ? 16 : 19;
}

export
	const
		TAX_VALUE = getTaxValue(),
		TRAVEL_ROUTES = [
			{id: 'return', name: () => i18next.t('StepCalculation__flight__Return flight')},
			{id: 'oneway', name: () => i18next.t('StepCalculation__flight__One way')}
		],
		TRAVEL_CLASSES = [
			{id: 'economy', name: () => i18next.t('StepCalculation__flight__Economy class')},
			{id: 'business', name: () => i18next.t('StepCalculation__flight__Business class')},
			{id: 'first', name: () => i18next.t('StepCalculation__flight__First class')}
		],
		CAR_TYPES = [
			{id: 'small', name: () => i18next.t('StepCalculation__car__Compact car'), helpText: () => i18next.t('StepCalculation__car__e.g.', {example: 'Opel Corsa'})},
			{id: 'mittel', name: () => i18next.t('StepCalculation__car__Middle class'), helpText: () => i18next.t('StepCalculation__car__e.g.', {example: 'Audi A4'})},
			{id: 'sport', name: () => i18next.t('StepCalculation__car__Super class'), helpText: () => i18next.t('StepCalculation__car__e.g.', {example: '7er BMW'})},
			{id: 'suv', name: () => i18next.t('StepCalculation__car__Van/SUV'), helpText: () => i18next.t('StepCalculation__car__e.g.', {example: 'VW Touareg'})}
		],
		FUEL_TYPES = [
			{id: 'petrol', name: () => i18next.t('StepCalculation__car__Petrol'), unit: 'liter'},
			{id: 'diesel', name: () => i18next.t('StepCalculation__car__Diesel'), unit: 'liter'},
			{id: 'electro_mix', name: () => i18next.t('StepCalculation__car__Electro german electromix'), unit: 'kWh'},
			{id: 'electro_eco', name: () => i18next.t('StepCalculation__car__Electro eco'), unit: 'kWh'},
			{id: 'gas_cng', name: () => i18next.t('StepCalculation__car__Gas natural'), unit: 'kg'},
			{id: 'gas_lpg', name: () => i18next.t('StepCalculation__car__Gas lpg'), unit: 'liter'},
			{id: 'plugin_hybrid', name: () => i18next.t('StepCalculation__car__Plugin hybrid'), unit: 'liter'}
		]
	;
