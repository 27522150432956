import {action, observable} from 'mobx';


export class Airports {

	constructor(rootStore, endpoint, token) {
		this.rootStore = rootStore;

		if (!endpoint) {
			throw new Error('You have to pass an endpoints as a string');
		}

		this._endpoint = endpoint;
		this._token = token;
	}

	@observable
	data = {};

	@action
	fetch() {
		const
			{language} = this.rootStore.settingsStore,
			lng = language === 'de' ? language : 'en',
			endpoint = `${this._endpoint}airports?api-token=${this._token}&language=${lng}`
		;

		return Promise.resolve()
			.then(() => this.data[language] || fetch(endpoint)
				.then((response) => response.json())
				.then((data) => {
					return data.map(({code, name}) => ({id: code.toLowerCase(), value: `${code} - ${name}`}));
				})
			).then((data) => {
				return this.data[language] = data;
			});
	}
}
