import {inject, PropTypes} from 'mobx-react';
import {object, shape} from 'prop-types';
import React, {Component} from 'react';
import {Translation} from 'react-i18next';

import IconTrash from 'assets/icons/icon-trash.svg';
import {CAR_TYPES, TRAVEL_ROUTES} from 'settings/constants';


@inject('calculator')
class Item extends Component {

	static propTypes = {
		calculator: shape({
			data: PropTypes.observableArray
		}).isRequired,
		data: object.isRequired
	}

	constructor(props) {
		super(props);

		this._onDeleteCalculation = this._onDeleteCalculation.bind(this);
	}

	render() {
		const
			{data: {namespace, params: {car_type, from_airport, to_airport, kilometers, passengers, travel_route}, result}} = this.props,
			routeName = TRAVEL_ROUTES.find(({id}) => id === travel_route)?.name()
		;

		var
			desc
		;

		switch (namespace) {
			case 'car':
				desc = `${CAR_TYPES.find(({id}) => id === car_type)?.name()}, ${new Intl.NumberFormat('de-DE', {maximumSignificantDigits: kilometers.toString().length}).format(kilometers)}km`;
				break;
			case 'general':
				desc = (<Translation>{(t) => t('StepProject__result__item__Individual CO2 compensation')}</Translation>);
				break;
			default:
				desc = (<Translation>{(t) => t('StepProject__result__item__{{from_airport}} > {{to_airport}}, {{passengers}}, {{routeName}}', {from_airport, to_airport, passengers, routeName})}</Translation>);
		}

		return (
			<li>
				<p>
					<span>{desc}</span>
					<span>
						{new Intl.NumberFormat('de-DE', {maximumSignificantDigits: result.toString().length}).format(result)} kg CO<sub>2</sub>
					</span>
				</p>
				<button type="button" onClick={this._onDeleteCalculation}>
					<IconTrash viewBox="0 0 100 100" />
					<Translation>{(t) => t('StepProject__result__item__Remove')}</Translation>
				</button>
			</li>
		);
	}

	_onDeleteCalculation() {
		const
			{calculator, data} = this.props
		;

		calculator.data = calculator.data.filter((item) => item !== data);

		if (calculator.data.length === 0) {
			calculator.step = 1;
		}
	}

}

export default Item;
