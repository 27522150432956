import {Airports as AirportStore} from './airports';
import {Calculator as CalculatorStore} from './calculator';
import {Countries as CountriesStore} from './countries';
import {Modal as ModalStore} from './modal';
import {Settings as SettingsStore} from './settings';


class RootStore {

	constructor({calculatorApi, distanceApi}, modules) {
		if (modules.includes('plane')) {
			this.airportStore = new AirportStore(this, distanceApi.url, distanceApi.token);
		}

		this.settingsStore = new SettingsStore(this);
		this.calculatorStore = new CalculatorStore(this, calculatorApi.url, calculatorApi.token, calculatorApi.paypalEnv);
		this.countriesStore = new CountriesStore(this, distanceApi.url, distanceApi.token);
		this.modalStore = new ModalStore(this);
	}

	onUpdateLanguage() {
		this.airportStore?.fetch();
		this.countriesStore?.fetch();

		if (this.calculatorStore.data.length > 0) {
			this.calculatorStore.fetchProjects();
		}
	}

}

export default RootStore;
