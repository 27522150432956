import i18next from 'i18next';
import {func, instanceOf} from 'prop-types';
import React, {Component} from 'react';


export default class Loading extends Component {

	static propTypes = {
		error: instanceOf(Error),
		retry: func
	}

	static defaultProps = {
		error: null,
		retry: () => {/* noop */}
	}

	// @TODO: We may need to check the possebility and benefit of avoiding
	// "Flash of loading component".
	// See: https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
	render() {
		const {error, retry} = this.props;
		let content = (<span className="animation">{i18next.t('common::Loading')}</span>);

		if (error) {
			window.console &&
			window.console.warn &&
			window.console.warn(error);

			content = (
				<span className="error">
					{i18next.t('common::Errors__An error occurred while loading')}
					<button title={i18next.t('common::Errors__Click here to retry loading')} type="button" onClick={retry}>
						{i18next.t('common::Errors__Retry')}
					</button>
				</span>
			);
		}

		return (<div className="loading">{content}</div>);
	}

}
