import React, {Component, createRef} from 'react';
import {array, element, func, shape} from 'prop-types';
import {observer} from 'mobx-react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Translation} from 'react-i18next';

import IconClose from 'assets/icons/icon-x.svg';


@observer
class Modal extends Component {
	static propTypes = {
		modal: shape({
			classNames: array,
			content: element,
			onClose: func
		}).isRequired
	}

	constructor(props) {
		super(props);

		this._onClickblocker = this._onClickblocker.bind(this);
		this._onClose = this._onClose.bind(this);
	}

	ref = createRef();

	componentDidUpdate() {
		const
			{ref: {current}} = this
		;

		if (current) {
			this._focus(current);
		}
	}

	render() {
		const
			{modal: {content, classNames}} = this.props
		;

		if (!content) {
			return null;
		}

		return (
			<div className="clickblocker" onClick={this._onClickblocker}>
				<TransitionGroup appear={true}>
					<CSSTransition classNames="modal-slidein" timeout={15000}>
						<div ref={this.ref} aria-modal="true" className={['modal', ...classNames].join(' ')} role="dialog" tabIndex="0">
							<div>
								{content}
							</div>
							<Translation>{(t) => (
								<button aria-controls="content" className="close" title={t('common::Modal__Close this layer')} type="button" onClick={this._onClose}>
									<span>
										{t('common::Modal__Close')}
									</span>
									<IconClose viewBox="0 0 100 100" />
								</button>
							)}</Translation>
						</div>
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}

	_focus(element) {
		this._previousActive = document.activeElement || document.body;

		element.focus();
	}

	_hide() {
		this.props.modal.content = null;
		this._focus(this._previousActive);
	}

	_onClickblocker({target: {classList}}) {
		if (classList.contains('clickblocker')) {
			this._hide();
		}
	}

	_onClose() {
		this._hide();
	}
}

export default Modal;
