import {inject} from 'mobx-react';
import {bool, number, object, shape} from 'prop-types';
import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Picture} from 'react-responsive-picture';

import IconLink from 'assets/icons/icon-link.svg';
import IconPin from 'assets/icons/icon-pin.svg';
import Checkbox from 'assets/img/checkbox.svg';
import {TAX_VALUE} from 'settings/constants';


@inject('calculator')
class Item extends Component {

	static propTypes = {
		calculator: shape({
			selectedProject: object
		}).isRequired,
		project: object.isRequired,
		sum: number.isRequired,
		isActive: bool,
		isRecommend: bool
	}

	static defaultProps = {
		isActive: false,
		isRecommend: false
	}

	constructor(props) {
		super(props);

		this._onSelect = this._onSelect.bind(this);
		this._onSubmit = this._onSubmit.bind(this);
	}

	render() {
		const
			{isActive, isRecommend, project, sum} = this.props,
			sources = project.images[0]?.thumbnails.map(({url, url2x, width}) => {
				return {
					srcSet: `${url} 1x, ${url2x} 2x`,
					media: `(max-width: ${width})`,
				};
			}),
			defaultSrc = project.images[0]?.thumbnails[0].url
		;

		return (
			<div className={['project', isActive ? 'is-active' : null].filter((className) => !!className).join(' ')}>
				<div className="headline">
					<button type="button" onClick={this._onSelect}>
						<Checkbox />
						<h4>
							{isRecommend && (<span><Translation>{(t) => t('StepProject__Our Projectsuggestion:')}</Translation> </span>)}
							{project.display_name}
						</h4>
					</button>
				</div>

				<div className={['image', !defaultSrc && 'no-image'].filter((name) => !!name).join(' ')}>
					{defaultSrc && (<Picture sources={sources} src={defaultSrc} />)}
					<span>{project.technology}</span>
				</div>

				<div className="info">
					<h5>
						<IconPin viewBox="0 0 100 100" />
						{project.region}, {project.country}
					</h5>
					<p className="info-text">
						{project.description}
					</p>
					<p className="price">
						{new Intl.NumberFormat('de-DE', {style: 'currency', currency: project.currency, currencyDisplay: 'code'}).format(project.price_with_tax * sum)}
						<em><Translation>{(t) => t('StepProject__Project incl tax', {value: TAX_VALUE})}</Translation></em>
					</p>
					<div className="actions">
						<button type="button" onClick={this._onSubmit}>
							<Translation>{(t) => t('StepProject__Encourage now')}</Translation>
						</button>
						<a href={project.website} rel="noopener noreferrer" target="_blank">
							<IconLink viewBox="0 0 100 100" />
							<Translation>{(t) => t('StepProject__More about the project')}</Translation>
						</a>
					</div>
				</div>
			</div>
		);
	}

	_onSelect() {
		const
			{calculator, project} = this.props
		;

		calculator.selectedProject = project;
	}

	_onSubmit() {
		const
			{calculator} = this.props,
			offset = window.scrollY + document.querySelector('.calculator').getBoundingClientRect().top
		;

		if ('scrollBehavior' in document.documentElement.style) {
			window.scrollTo({
				top: offset,
				behavior: 'smooth'
			});
		} else {
			window.scrollTo(0, offset);
		}

		calculator.step = 3;
	}

}

export default Item;
