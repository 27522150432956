import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHRBackend from 'i18next-xhr-backend';
import {arrayOf, element, func, node, object, oneOfType, string} from 'prop-types';
import React, {Component} from 'react';
import {initReactI18next} from 'react-i18next';


function setup(url, lng) {
	return i18next
		.on('languageChanged', () => document.documentElement.lang = i18next.language)
		.use(XHRBackend)
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			backend: {
				loadPath: () => `${url.origin}/static/js/locales/{{lng}}/{{ns}}.json`
			},
			lng: lng || 'de',
			fallbackLng: ['en', 'de'],
			load: 'languageOnly',
			keySeparator: '__',
			nsSeparator: '::',
			ns: ['common', 'translation'],
			preload: ['en', 'de'],
			returnEmptyString: false,
			parseMissingKeyHandler: (key) => `No translation found for "${key}"`
		}).then(() => i18next);
}

class I18nextApp extends Component {

	static propTypes = {
		children: oneOfType([arrayOf(node), node]).isRequired,
		loading: oneOfType([element, func, node]).isRequired,
		url: object.isRequired,
		lng: string
	}

	static defaultProps = {
		lng: null
	}

	state = {
		isLoading: true,
		error: null
	}

	componentDidMount() {
		const
			{lng, url} = this.props
		;

		setup(url, lng)
			.then(() => this.setState({isLoading: false}))
			.catch((error) => this.setState({error}));
	}

	render() {
		const
			{error, isLoading} = this.state,
			{children, loading: Loading} = this.props
		;

		if (isLoading || error) {
			return (typeof Loading === 'function') ? (<Loading error={error} />) : Loading;
		}

		return children;
	}

}


export {I18nextApp, i18next, setup};
