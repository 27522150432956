import {inject, observer} from 'mobx-react';
import {number, shape} from 'prop-types';
import React, {Component} from 'react';
import {Translation} from 'react-i18next';


@inject('calculator')
@observer
class Progress extends Component {

	static propTypes = {
		calculator: shape({
			step: number
		}).isRequired
	}

	render() {
		const
			{calculator: {step}} = this.props,
			steps = [
				(<Translation key="1">{(t) => t('Progress__Progress Calculation')}</Translation>),
				(<Translation key="2">{(t) => t('Progress__Progress Select project')}</Translation>),
				(<Translation key="3">{(t) => t('Progress__Progress Payment and Billinginformations')}</Translation>),
				(<Translation key="4">{(t) => t('Progress__Progress Certificate')}</Translation>)
			]
		;

		return (
			<div className={['content', 'content-progress', `step-${step}`].join(' ')}>
				<div>
					<span>
						{step}/{steps.length} {steps[step - 1]}
					</span>
				</div>
			</div>
		);
	}

}

export default Progress;
