import React, {Component} from 'react';
import {any, array, arrayOf, func, number, object, oneOfType, shape, string} from 'prop-types';


class Choice extends Component {
	static propTypes = {
		name: string.isRequired,
		options: oneOfType([
			shape({
				id: oneOfType([
					string,
					number
				]),
				name: oneOfType([
					string,
					number
				]),
				helpText: shape({
					key: string,
					values: object
				})
			}),
			arrayOf(
				shape({
					id: oneOfType([
						string,
						number
					]),
					name: oneOfType([
						string,
						number
					]),
					helpText: string
				})
			)
		]).isRequired,
		classNames: arrayOf(string),
		component: any,
		disabledOptions: array,
		errors: oneOfType([
			arrayOf(
				shape({
					field: string,
					messages: arrayOf(
						string
					)
				})
			),
			shape({
				field: string,
				messages: arrayOf(
					string
				)
			})
		]),
		label: string,
		value: oneOfType([
			string
		]),
		onChange: func
	}

	static defaultProps = {
		classNames: [],
		component: 'div',
		disabledOptions: [],
		errors: [],
		label: null,
		value: null,
		onChange: () => {/* NOOP */}
	}

	constructor(props) {
		super(props);

		this.isChecked = this.isChecked.bind(this);
		this.onChange = this.onChange.bind(this);

		this.state = {
			value: props.value
		};
	}

	render() {
		const
			{classNames: classes, component: Component, disabledOptions, label, name, options, errors} = this.props,
			error = [].concat(errors).length > 0 ?
				[].concat(errors)[0].messages
					.map((message, index) => <li key={index}>{message}</li>) :
				null,
			classNames = [
				'generic-form-field',
				'generic-' + this.fieldType,
				this.fieldType + '-' + name.toLowerCase(),
				error ? 'has-errors' : null
			],
			onChange = this.onChange,
			choices = [].concat(options).map(({id, id: value, name: optionName, helpText}) => {
				const
					disabled = disabledOptions.includes(id),
					optionClassNames = [
						...classNames,
						`${this.fieldType}-${id.toLowerCase()}`,
						helpText ? 'has-helptext' : null
					].filter((className) => !!className).join(' '),
					checked = this.isChecked(id)
				;

				return (<Component key={id} className={optionClassNames}>
					<input {...{name, checked, onChange, disabled, id, value}} type={this.fieldType} />
					<label htmlFor={id} title={optionName}>{optionName}{helpText && <span>{helpText}</span>}</label>
					{error && <ul key="input-errors">{error}</ul>}
				</Component>);
			})
		;

		return choices.length > 1 ?
			(<div className={['input-group', `group-${this.fieldType}`, ...classes].join(' ')}>
				{label && <h4>{label}</h4>}
				<div>
					{choices}
				</div>
			</div>) :
			choices
		;
	}

	isChecked(id) {
		const
			{value} = this.state
		;

		return value === id;
	}

	onChange(event) {
		const
			{name, onChange} = this.props,
			{value} = event.currentTarget
		;

		this.setState(() => ({value}));

		onChange(name, value);
	}
}

export default Choice;
