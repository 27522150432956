import {action, observable} from 'mobx';


export class Countries {

	constructor(rootStore, endpoint, token) {
		this.rootStore = rootStore;

		if (!endpoint) {
			throw new Error('You have to pass an endpoints as a string');
		}

		this._endpoint = endpoint;
		this._token = token;
	}

	@observable
	data = {};

	@action
	fetch() {
		const
			{language} = this.rootStore.settingsStore,
			lng = language === 'de' ? language : 'en',
			endpoint = `${this._endpoint}countries?api-token=${this._token}&language=${lng}`
		;

		return Promise.resolve()
			.then(() => this.data[language] || fetch(endpoint)
				.then((response) => response.json())
				.then((data) => {
					const
						newData = [
							...data
								.filter(({code}) => ['de', 'at', 'ch'].includes(code.toLowerCase()))
								.sort(({name: nameA}, {name: nameB}) => {
									const
										normalizedA = nameA.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
										normalizedB = nameB.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
									;

									if (normalizedA < normalizedB) {
										return -1;
									}

									if (normalizedA > normalizedB) {
										return 1;
									}

									return 0;
								}),
							...data
								.sort(({name: nameA}, {name: nameB}) => {
									const
										normalizedA = nameA.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
										normalizedB = nameB.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
									;

									if (normalizedA < normalizedB) {
										return -1;
									}

									if (normalizedA > normalizedB) {
										return 1;
									}

									return 0;
								})
						]
					;

					return newData.map(({code, name}) => ({id: code.toLowerCase(), name}));
				})
			).then((data) => {
				return this.data[language] = data;
			});
	}
}
