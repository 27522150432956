const url = new URL(document.currentScript.src);
__webpack_public_path__ = (`${url.origin}/static/js/`); // eslint-disable-line no-undef

import 'current-script-polyfill';
import 'url-polyfill';

import {Provider} from 'mobx-react';
import React from 'react';
import {render} from 'react-dom';
import TagManager from 'react-gtm-module';

import {I18nextApp} from 'locales';
import Loading from 'components/generic/loading/Loading';
import Calculator from 'components/calculator/Calculator';
import Modal from 'components/generic/modal/Modal';
import RootStore from 'stores';


export function changeLanguage(lng) {
	const
		event = new CustomEvent('climatePartner.settings.changeLanguage', {detail: {lng}})
	;

	window.document.dispatchEvent(event);
}

export function init(config) {
	// Startup the application using the following steps:
	testPromiseSupport(function() {
		featureDetect();
		executeApp(config);
	});
}

// Helper to load a script using given source and continue using a callback.
function injectScript(src, callback) {
	var
		doc = window.document,
		script = doc.createElement('script')
	;

	script.type = 'text/javascript';
	script.charset = 'utf-8';
	script.async = true;
	script.defer = true;
	script.src = src;
	script.onload = callback || null;
	doc.body.appendChild(script);
}

// Test support of promises which is required by webpack. IE11 doesn't support
// promises. So we load a polyfill for promises without using dynamic imports
// (returns promise). A webpack copy task moves the promise-polyfill from
// node_modules into the build directory.
function testPromiseSupport(callback) {
	var win = window;

	return win.Promise ?
		callback() :
		injectScript(url.origin + '/static/js/promise-polyfill.js', callback);
}

function featureDetect() {
	var
		doc = document,
		html = doc.documentElement
	;

	html.classList.remove('no-js');
	html.classList.add('js');
}

function executeApp(config) {
	var win = window;

	Promise.all([
		// Fetch polyfills if required:
		(!!win.Symbol || import(/* webpackChunkName: "polyfill-symbol" */ 'es6-symbol/implement')),
		(!!win.Array.prototype.includes || import(/* webpackChunkName: "polyfill-array-includes" */ 'mdn-polyfills/Array.prototype.includes')),
		(!!win.Array.prototype.find || import(/* webpackChunkName: "polyfill-array-find" */ 'mdn-polyfills/Array.prototype.find')),
		(!!win.Object.assign || import(/* webpackChunkName: "polyfill-object-assign" */ 'es6-object-assign/auto')),
		(!!win.fetch || import(/* webpackChunkName: "polyfill-fetch" */ 'fetch-polyfill'))
	]).then(function() {
		renderApp(config);
	});
}

function renderApp(config) {
	if (!config.selector) {
		throw new Error('You have to pass a selector as a string');
	}

	const
		app = document.getElementById(config.selector),
		apiDistanceUrl = config.distanceApiUrl,
		apiDistanceToken = config.distanceApiKey,
		apiCalculatorUrl = config.calculatorApiUrl,
		apiCalculatorToken = config.calculatorApiKey,
		paypalEnv = config.paypalEnv || 'sandbox',
		tagManagerArgs = {
			gtmId: config.gtmId
		},
		rootStore = new RootStore({
			distanceApi: {url: apiDistanceUrl, token: apiDistanceToken},
			calculatorApi: {url: apiCalculatorUrl, token: apiCalculatorToken, paypalEnv}
		}, config.modules),
		stores = {
			airports: rootStore.airportStore,
			calculator: rootStore.calculatorStore,
			countries: rootStore.countriesStore,
			modal: rootStore.modalStore,
			settings: rootStore.settingsStore
		}
	;

	if (!app) {
		throw new Error(`No Element found with id '${config.selector}'`);
	}

	if (tagManagerArgs.gtmId) {
		TagManager.initialize(tagManagerArgs);
	}

	window.document.addEventListener('climatePartner.settings.changeLanguage', ({detail}) => {
		stores.settings.changeLanguage(detail.lng);
	});

	render(
		<I18nextApp lng={config.language} loading={(<Loading />)} url={url}>
			<Provider {...stores}>
				<div className="climatepartner-calculator-wrapper">
					<Calculator config={config} />
					<Modal {...{modal: stores.modal}} />
				</div>
			</Provider>
		</I18nextApp>
	, app);
}
