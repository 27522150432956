import {action, observable} from 'mobx';


const
	TYPES = {
		plane: 'flight-calculation-1',
		car: 'car-calculation-2'
	}
;


export class Calculator {

	constructor(rootStore, endpoint, token, paypalEnv) {
		this.rootStore = rootStore;

		if (!endpoint) {
			throw new Error('You have to pass an endpoints as a string');
		}

		this._endpoint = endpoint;
		this._token = token;
		this._paypalEnv = paypalEnv;
	}

	@observable
	state = 'pending'

	@observable
	certificate = null

	@observable
	step = 1

	@observable
	data = []

	@observable
	selectedProject = null

	@observable
	projects = {}

	fetchResult(type) {
		const
			endpoint = `${this._endpoint}calculate?api-key=${this._token}`,
			data = this.data.find((data) => !data.result)
		;

		if (!data.params.type) {
			data.params.type = TYPES[type];
		}

		return Promise.resolve()
			.then(() => {
				return fetch(endpoint, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({calculation: data.params})
				});
			})
			.then((response) => response.json())
			.then(({result}) => {
				data.namespace = type;
				data.result = Math.round(result);
			});
	}

	@action
	fetchProjects() {
		const
			{language} = this.rootStore.settingsStore,
			endpoint = `${this._endpoint}projects?api-key=${this._token}&language=${language}`
		;

		this.state = 'pending';

		return Promise.resolve()
			.then(() => this.projects[language] || fetch(endpoint).then((response) => response.json()))
			.then((projects) => {
				if (Array.isArray(projects)) {
					this.projects[language] = projects.slice(0, 3);
					this.selectedProject = projects[0];

					this.state = 'done';
				} else {
					this.state = 'error';
				}
			});
	}

	@action
	authorized(data) {
		const
			{language} = this.rootStore.settingsStore,
			lng = language === 'de' ? language : 'en',
			{orderID} = data,
			endpoint = `${this._endpoint}payments/${orderID}/callback?api-key=${this._token}&language=${lng}`
		;

		return fetch(
			endpoint,
			{
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({action: 'CAPTURE'})
			}
		)
		.then((res) => res.json());
	}

	@action
	onPayment(data) {
		const
			endpoint = `${this._endpoint}payments?api-key=${this._token}`
		;

		return fetch(
			endpoint,
			{
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}
		)
		.then((res) => {
			if (!res.ok) {
				return Promise.reject(new Error('fail'));
			}

			return res.json();
		})
		.then((data) => {
			return data.payment_reference;
		}).catch(error => console.log(error));
	}

}
